<template>
    <div>
        <div>
        <div style='text-align:center;background-color:lightgrey;' class='pt-2 pb-2'>{{dataProvider.libelle}}</div>
        <hr style="margin-bottom: 30px">
        <v-text-field
            :label=phrase
            outlined
            readonly
            v-model="anomalie"
        ></v-text-field>       
        <v-text-field
            label="L'intervention sera clôturée automatiquement en"
            outlined
            readonly
            v-model="cloture"
            @change='emit'
        ></v-text-field>
    </div>       
    </div>
</template>


<script>

  export default {
        name: "cx-cloture-qualigaz",
        props: {
            dataProvider: {
                type: Object
                /*
                {
                    label: '',
                    data: ''
                }
                */
            },
            intervention: {
                type: Number
            },
        },
        data() {
        return {
            phrase: null,
            anomalie: null,
            cloture: null
        }
        },
        mounted() {
            // let data = {
            //         appareil_id : this.appareil_id,
            //         obs_modification : this.commentaire
            //     }

            this.loading = true;

            this.$services.request.get('api/pwa/mes-interventions/intervention/'+ this.intervention +'/response-predominant')
                        .then((response)=>{
                            console.log('dataProvider: ', this.dataProvider.data);
                            this.phrase = response.phrase
                            this.dataProvider.data = response.cloture_id
                            this.cloture = response.cloture
                            this.anomalie = response.anomalie
                            console.log(this.anomalie);
                            
                            // this.$emit('change', this.getNomenclatureSignaturePictureName());
                            this.loading = false;
                        })
                        .catch( () => {
                            this.$services.powerLib.notification.showSnackbar({
                                message : 'Une erreur est survenue lors de la récupération des données',
                                type : 'error'
                            })
                            this.loading = false;
                        })
        },
        methods: {
            emit(){
                return this.$emit('change',this.dataProvider.data)
            }
        }
    }
</script>