<template>
    <v-card flat >
        <div style='text-align:center;background-color:#25207A;color:white;font-size: large;' class='pt-2'>Catégorie</div>
        <div style='text-align:center;background-color:#25207A;color:white;font-size: large;' class='pb-2'>{{dataProvider.libelle}}</div>
        <hr>
        <v-radio-group  v-model="value" @change='emit' row>
            <v-radio class='mx-auto pa-3' v-for="(item, index) in dataProvider.choices" :key="index" :label="item.name" :value='item.value'
            ></v-radio>
        </v-radio-group>
    </v-card>
</template>


<script>

  export default {
        name: "cx-radio-qualigaz",
        props: {
            dataProvider: {
                type: Object
                /*
                {
                    libelle: '',
                    choices: '',
                    data: ''
                }
                */
            }
        },
        data(){
            return {
                value: ''
            }
        },
        mounted(){
            this.value = this.dataProvider.data
        },
        methods: {
            emit(){
                return this.$emit('change',this.value)
            }
        }
    }
</script>
