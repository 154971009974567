<template>
    <div>
        <v-text-field 
            outlined 
            :label="dataProvider.libelle" 
            v-model="dataProvider.data" 
            type="number"
            @change='emit' 
            @keyup="emit">
    </v-text-field>
    </div>
</template>


<script>

export default {
    name: "cx-number",
    props: {
        dataProvider: {
            type: Object
            /*
            {
                label: '',
                data:''
            }
            */
        }
    },
    methods: {
        emit() {
            return this.$emit('change', this.dataProvider.data)
        }
    }
}
</script>
